/* global MutationObserver */
import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect () {
    this.initializeAllSelects()
  }

  initializeAllSelects () {
    if (process.env.RAILS_ENV !== 'test') {
      this.element.querySelectorAll('select').forEach((element) => {
        element.slimSelectInstance = this.initializeSelect(element)
      })

      this.observeFieldsetChanges()
    }
  }

  initializeSelect (element) {
    const disabled = element.disabled || element.closest('fieldset')?.disabled || false
    const showSearch = element.dataset.showSearch || false

    return new SlimSelect({
      select: element,
      settings: {
        showSearch,
        disabled
      }
    })
  }

  observeFieldsetChanges () {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.attributeName === 'disabled') {
          const target = mutation.target

          target.querySelectorAll('select:disabled').forEach((element) => {
            element.disabled = target.disabled
          })
        }
      }
    })

    this.element.querySelectorAll('fieldset').forEach((fieldset) => {
      observer.observe(fieldset, { attributes: true })
    })
  }
}
