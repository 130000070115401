import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['country', 'text', 'select']
  }

  connect () {
    this.chooseRegionControl()
  }

  chooseRegionControl () {
    const alpha3 = this.countryTarget.selectedOptions[0].dataset.alpha3
    this.determineInput(alpha3)
  }

  determineInput (alpha3) {
    if (alpha3 === 'USA') {
      this.textTarget.classList.add('hidden')
      this.selectTarget.classList.remove('hidden')
    } else {
      this.textTarget.classList.remove('hidden')
      this.selectTarget.classList.add('hidden')
    }
  }
};
