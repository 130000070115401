import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['fieldset', 'form']
  }

  static get values () {
    return {
      company: Boolean
    }
  }

  toggleForm ({ params }) {
    if (params.toggle) {
      this.formTarget.classList.remove('hidden')
      this.fieldsetTarget.disabled = this.companyValue
    } else {
      this.formTarget.classList.add('hidden')
      this.fieldsetTarget.disabled = true
    }
  }
};
